//
//    Generated by trinidad-ui!
//
//
//    ██████╗  ██████╗     ███╗   ██╗ ██████╗ ████████╗    ███████╗██████╗ ██╗████████╗
//    ██╔══██╗██╔═══██╗    ████╗  ██║██╔═══██╗╚══██╔══╝    ██╔════╝██╔══██╗██║╚══██╔══╝
//    ██║  ██║██║   ██║    ██╔██╗ ██║██║   ██║   ██║       █████╗  ██║  ██║██║   ██║
//    ██║  ██║██║   ██║    ██║╚██╗██║██║   ██║   ██║       ██╔══╝  ██║  ██║██║   ██║
//    ██████╔╝╚██████╔╝    ██║ ╚████║╚██████╔╝   ██║       ███████╗██████╔╝██║   ██║
//    ╚═════╝  ╚═════╝     ╚═╝  ╚═══╝ ╚═════╝    ╚═╝       ╚══════╝╚═════╝ ╚═╝   ╚═╝
//
//
//    All changes in this file will be overwritten next time
//    someone adds or generates new assets.
//
//    Read more on https://git.svenskaspel.se/trinidad/trinidad-ui ...
//
//    @author Team Design-tech
//
//    ---

const definedEmblem = 'vinnare';
const path = '/images/ui/brands/';
const cdn = global.window &&
  window.svs &&
  window.svs.core &&
  window.svs.core.config &&
  window.svs.core.config.data &&
  window.svs.core.config.data.svsconfig ?
window.svs.core.config.data.svsconfig.cdn :
'';
const hashedBrands = {
  '17ellermer': { svg: '17ellermer-1ad715340679a1dcc302c97cd759ffc56c064193.svg', alt: '17 eller mer' },
  '3irad': { svg: '3irad-95e0b4c914ecdaf71bceb9a14be5760e9e339367.svg', alt: '3 i rad' },
  'andra-chansen': { svg: 'andra-chansen-ebc9d4fcb2eddc77578b7ec19675e685d94d560e.svg', alt: 'Andra chansen' },
  'bil-triss': { svg: 'bil-triss-1a76d00907581859b112da90627168db198008a2.svg', alt: 'BilTriss' },
  'bil-triss-vit': { svg: 'bil-triss-vit-8e73ba77027f4c196f9df889137913b3f29fbab2.svg', alt: 'BilTriss' },
  bingo: { svg: 'bingo-7b4a4241c83ceba9150020a7f7323822b32ffa22.svg', alt: 'Bingo' },
  bomben: { svg: 'bomben-d2362b6b169fcaae4b52b32d43b36c1a01880553.svg', alt: 'Bomben' },
  'bomben-100': { svg: 'bomben-100-d2362b6b169fcaae4b52b32d43b36c1a01880553.svg', alt: 'Bomben' },
  casino: { svg: 'casino-94340d880f29c684db83f923a0eeed956506d952.svg', alt: 'Casino' },
  challenge: { svg: 'challenge-0676357bb746e15eaacda6e59089ce09aad75d79.svg', alt: 'Challenge' },
  'challenge-100': { svg: 'challenge-100-0676357bb746e15eaacda6e59089ce09aad75d79.svg', alt: 'Challenge' },
  'dubbel-triss': { svg: 'dubbel-triss-aaa6726f86bf72e31ae4f08d33c4a367c0c3dea4.svg', alt: 'DubbelTriss' },
  'dubbel-triss-vit': { svg: 'dubbel-triss-vit-960e874d9c1100cc583b709c843f5e6a7ba280ad.svg', alt: 'DubbelTriss' },
  'em-tipset': { svg: 'em-tipset-e7090b4a757e33f48de18a87feb03154d4831fa4.svg', alt: 'EM-Tipset' },
  'em-tipset-europa': { svg: 'em-tipset-europa-ffb56f3237c6dd3ba2b0d3424a68eef255177989.svg', alt: 'EM-Tipset' },
  eurojackpot: { svg: 'eurojackpot-bdf1c9b8e4c8a09599c3b20f609c4a5cb37c133a.svg', alt: 'Eurojackpot' },
  'eurojackpot-100': { svg: 'eurojackpot-100-791ababa09cbb94f7ad8a9e75f691289b486952a.svg', alt: 'Eurojackpot' },
  europatipset: { svg: 'europatipset-9c9a6d595c5456157ef800fc742f52a6d842bbb6.svg', alt: 'Europatipset' },
  extratipset: { svg: 'extratipset-0450e42e1de82ab129ef33b503d6d30037bb2a64.svg', alt: 'Extratipset' },
  'fler-lotter': { svg: 'fler-lotter-c7ee60804d0e285d8dd8773455be045159c03cb2.svg', alt: 'Fler lotter' },
  fulltraff: { svg: 'fulltraff-ffa34436a0fcd0561066fc14bf1ada8948637741.svg', alt: 'Fullträff' },
  grasroten: { svg: 'grasroten-0b15405ca40225824832ad41c3b2361e88e285a9.svg', alt: 'Gräsroten' },
  hastar: { svg: 'hastar-3a20b2aa1a45a6d9307808bbfb85d97413df726c.svg', alt: 'Hästar' },
  'hastar-primary': { svg: 'hastar-primary-60009919748b427af465dee39625c9c8c7cdd056.svg', alt: 'Hästar' },
  'hastar-secondary': { svg: 'hastar-secondary-4df80e2b57ef64dc96fed2792228b5c1e899d11b.svg', alt: 'Hästar' },
  keno: { svg: 'keno-282b587bdfac3a4fda139f08a409ba2643408533.svg', alt: 'Keno' },
  kenoxpress: { svg: 'kenoxpress-cd7d631e4ed50bc1b3517c2a7479f48bdfe95b47.svg', alt: 'KenoXpress' },
  'kenoxpress-full': { svg: 'kenoxpress-full-077973a06a05f4bd20f78059ce7c74c80fa09c96.svg', alt: 'KenoXpress' },
  langen: { svg: 'langen-b744597c0f2ab69eb95e590e1788affbaee6a613.svg', alt: 'Lången' },
  'langen-100': { svg: 'langen-100-af088d5eb21de00fd1ce5177a79fabdf6098c395.svg', alt: 'Lången' },
  langenxl: { svg: 'langenxl-e8ba534443793d46bd14c3e70b35f95932889424.svg', alt: 'Lången XL' },
  'langenxl-100': { svg: 'langenxl-100-cc8ee4b37a0f0a2fbd4bae1032f1c2825b739d3c.svg', alt: 'Lången XL' },
  live: { svg: 'live-e462c58b8e6c400936a028732948250fee94c23f.svg', alt: 'Live' },
  'live-100': { svg: 'live-100-af40244f2f97eee8e9caf2a10ee057b6f2ae486f.svg', alt: 'Live' },
  'live-casino': { svg: 'live-casino-3fe8add031f62da568c042acadb65d4a93236e9e.svg', alt: 'Live Casino' },
  lordagsgodis: { svg: 'lordagsgodis-73315fbab4bb4a13503dcbce7da518c0f2e6ca0f.svg', alt: 'Lördagsgodis' },
  'lordagsgodis-oneline': { svg: 'lordagsgodis-oneline-8fa5a2a2850e6065a6a243df0b738c55cabb215a.svg', alt: 'Lördagsgodis' },
  'lordagsgodis-oneline-red': { svg: 'lordagsgodis-oneline-red-ea4e5bdbb9d5e3ef62415ab7b9fda435ccd0ac5c.svg', alt: 'Lördagsgodis' },
  'lordagsgodis-red': { svg: 'lordagsgodis-red-9a34d99f71562da6ebe24d3679126ee333309ac9.svg', alt: 'Lördagsgodis' },
  lotto: { svg: 'lotto-ebc9d4fcb2eddc77578b7ec19675e685d94d560e.svg', alt: 'Lotto' },
  maltipset: { svg: 'maltipset-4ff5fe938c7bdab82d4778c416269f91e94b9236.svg', alt: 'Måltipset' },
  matchen: { svg: 'matchen-4d376e0a22d612e7f9f3b81befc57abc88792bc2.svg', alt: 'Matchen' },
  'matchen-100': { svg: 'matchen-100-4d376e0a22d612e7f9f3b81befc57abc88792bc2.svg', alt: 'Matchen' },
  'mini-triss': { svg: 'mini-triss-4278ba1b65a0729c70939e377503a436fc8de52b.svg', alt: 'MiniTriss' },
  'mini-triss-vit': { svg: 'mini-triss-vit-93a469925169d07de865d52a5aeac19c16d53714.svg', alt: 'MiniTriss' },
  mittemellan: { svg: 'mittemellan-a0431d85481725b08ad4c7023d7859e621f2a89d.svg', alt: 'Mittemellan' },
  mixen: { svg: 'mixen-4c01a2a3bc9fa974bd6226b6d0b15e8629c14b36.svg', alt: 'Mixen' },
  'mixen-100': { svg: 'mixen-100-a86dc3f500b646a1cc231649ccefba17d6068dce.svg', alt: 'Mixen' },
  mixenxl: { svg: 'mixenxl-4c6340ff698f51c2a9de312429a2923a770ea89b.svg', alt: 'Mixen XL' },
  'mixenxl-100': { svg: 'mixenxl-100-3ac030205a2e66e447bd8569166d97b110f39972.svg', alt: 'Mixen XL' },
  momang: { svg: 'momang-3654cb9acb7d85435580c85740a706b6935146d3.svg', alt: 'Momang casino' },
  'momang-inverted': { svg: 'momang-inverted-867ae777c8f4e14a56f8bad7b25c6b1e2ec727c7.svg', alt: 'Momang casino' },
  oddset: { svg: 'oddset-6453dd8366c93da09231cc7c5ae69fd93474a32e.svg', alt: 'Oddset' },
  'oddset-100': { svg: 'oddset-100-6453dd8366c93da09231cc7c5ae69fd93474a32e.svg', alt: 'Oddset' },
  'oddset-inverted': { svg: 'oddset-inverted-99f7b01b1ea09af3031a9c11a29052705b84969c.svg', alt: 'Oddset inverterad' },
  poker: { svg: 'poker-eb65ab0bc3eccbd39f68b6adb8e812928a46f621.svg', alt: 'Poker' },
  powerplay: { svg: 'powerplay-7a80783e6f6c05f23330fe4ab672ddf57b3078ac.svg', alt: 'Powerplay' },
  'powerplay-100': { svg: 'powerplay-100-7a80783e6f6c05f23330fe4ab672ddf57b3078ac.svg', alt: 'Powerplay' },
  rubbet: { svg: 'rubbet-0093d1df465f1ceb8daaa1fce4e978e9a621f370.svg', alt: 'Rubbet' },
  'rubbet-black': { svg: 'rubbet-black-0948f92184dfa24a50418928d71f63e31bcaa5a4.svg', alt: 'Rubbet' },
  rubbetcom: { svg: 'rubbetcom-bd163e7ae0757cbf7aa719a0f2ae1a7b12d01e20.svg', alt: 'Rubbet' },
  'rubbetcom-black': { svg: 'rubbetcom-black-98d777e1d0f2f615b4583a3f43687a85c25326df.svg', alt: 'Rubbet' },
  'skrap-bingo': { svg: 'skrap-bingo-c89eaeb55006cdeffc0ae0c8c2a7c7369c817430.svg', alt: 'SkrapBingo' },
  'skrap-kryss': { svg: 'skrap-kryss-be3db3931b5b699dc792f3b3b687cd7c24860709.svg', alt: 'SkrapKryss' },
  'skrap-matcha': { svg: 'skrap-matcha-b0acae7a63494430bc844af9ba1c2ab0c756d7be.svg', alt: 'SkrapMatcha' },
  'skrap-neg': { svg: 'skrap-neg-5cd1c212337978a4a34bbd056f63272bb4d887ad.svg', alt: 'Skrap' },
  'skrap-pacman': { svg: 'skrap-pacman-2c1230d4d0654dcf42a8385bd1a7027963138f2d.svg', alt: 'Skrap-PAC-MAN' },
  skrap7an: { svg: 'skrap7an-59c43e2972a752a8ff9d6023185ac86bf520c64b.svg', alt: 'Skrap7:an' },
  skrapspel: { svg: 'skrapspel-d80713e0c95af49e856c8af493a7bd6e378c16f8.svg', alt: 'Skrapspel' },
  stryktipset: { svg: 'stryktipset-77c8526c182aa494c5e2743f148fa6d2fabdae0c.svg', alt: 'Stryktipset' },
  'stryktipset-90': { svg: 'stryktipset-90-455406db606abfbdfecd72db05f8f019e6bd3cdd.svg', alt: 'Stryktipset' },
  supportern: { svg: 'supportern-cbb63fafb39b57ba7c7dd04193445df704cac1de.svg', alt: 'supportern' },
  'supportern-neg': { svg: 'supportern-neg-aee564099efd4f459f43874f80a7bc4834800575.svg', alt: 'supportern' },
  svenskaspel: { svg: 'svenskaspel-f37154aee62f6a260800fc402afa42c8a382a5ca.svg', alt: 'Svenska Spel' },
  'svenskaspel-100': { svg: 'svenskaspel-100-98cd7456d58af53ac1e66c79a9a601b404c4122c.svg', alt: 'Svenska Spel' },
  'svenskaspel-twolines': { svg: 'svenskaspel-twolines-48f539c5dd02257c129450918ec409e86904d8d0.svg', alt: 'Svenska Spel' },
  'svenskaspel-twolines-neg': { svg: 'svenskaspel-twolines-neg-38f8a54b61278451cc50ab47ec0ebe1d5fb995ae.svg', alt: 'Svenska Spel' },
  topptipset: { svg: 'topptipset-9c63fadb00c14165ccb49541b8503c6ff2f5bb14.svg', alt: 'Topptipset' },
  triss: { svg: 'triss-509fda23cce90bdfba3775af058542dd7b634783.svg', alt: 'Triss' },
  'triss-vit': { svg: 'triss-vit-f884a3a3e22adf3172ce4e91a93aa34f75515cd8.svg', alt: 'Triss' },
  vikinglotto: { svg: 'vikinglotto-9105a296bcf6e6b25034744ca1db0df95335cf04.svg', alt: 'Vikinglotto' },
  vinnare: { svg: 'vinnare-66fb697ea8d797fa5d48ff9ddb2c661ca313573b.svg', alt: 'Vinnare' },
  'vm-tipset': { svg: 'vm-tipset-0450e42e1de82ab129ef33b503d6d30037bb2a64.svg', alt: 'VM-Tipset' },
  'vm-tipset-europa': { svg: 'vm-tipset-europa-2b92b4205d717fd0d1c9cb790233ab1767ee00bf.svg', alt: 'VM-Tipset' }
};
const guards = {
  '17-eller-mer': '17ellermer',
  '3-i-rad': '3irad',
  'biltriss-vit': 'bil-triss-vit',
  biltriss: 'bil-triss',
  netcasino: 'casino',
  'dubbeltriss-vit': 'dubbel-triss-vit',
  dubbeltriss: 'dubbel-triss',
  'eurojackpot tisdag': 'eurojackpot',
  'eurojackpot-tisdag': 'eurojackpot',
  'eurojackpot fredag': 'eurojackpot',
  'eurojackpot-fredag': 'eurojackpot',
  'hastar-primary': 'hastar-primary',
  'trav-galopp-primary': 'hastar-primary',
  'trav--galopp': 'hastar',
  'trav-och-galopp': 'hastar',
  'hastar-secondary': 'hastar-secondary',
  'trav-galopp-secondary': 'hastar-secondary',
  'trav--galopp-secondary': 'hastar-secondary',
  'trav--galopp-primary': 'hastar-secondary',
  'trav-och-galopp-secondary': 'hastar-secondary',
  'trav-och-galopp-primary': 'hastar-secondary',
  hastar: 'hastar',
  'trav-galopp': 'hastar',
  skrapkeno: 'keno',
  'skrap-keno': 'keno',
  'live casino': 'live-casino',
  'lotto onsdag': 'lotto',
  'lotto-onsdag': 'lotto',
  'lotto lordag': 'lotto',
  'lotto-lordag': 'lotto',
  skraplotto: 'lotto',
  'skrap-lotto': 'lotto',
  'minitriss-vit': 'mini-triss-vit',
  minitriss: 'mini-triss',
  'mitt-emellan': 'mittemellan',
  'momang-black': 'momang-inverted',
  'mixen-inverted': 'oddset-inverted',
  'langen-inverted': 'oddset-inverted',
  'sportsbook-inverted': 'oddset-inverted',
  mixen: 'oddset',
  langen: 'oddset',
  sportsbook: 'oddset',
  skrapbingo: 'skrap-bingo',
  skrapkryss: 'skrap-kryss',
  skrapmatcha: 'skrap-matcha',
  'skrap-neg': 'skrap-neg',
  'skrap-pac-man': 'skrap-pacman',
  'skrap-7an': 'skrap7an',
  skrappyramid: 'skrapspel',
  'skrap-familjen': 'skrapspel',
  skrapstjarna: 'skrapspel',
  'triss-familjen-vit': 'triss-vit',
  'triss-30-vit': 'triss-vit',
  'triss-40-vit': 'triss-vit',
  'triss-40-kr-vit': 'triss-vit',
  'triss-90-vit': 'triss-vit',
  'triss-150-vit': 'triss-vit',
  'triss-premium-vit': 'triss-vit',
  'triss-adventskalender-vit': 'triss-vit',
  'triss-familjen': 'triss',
  'triss-30': 'triss',
  'triss-40': 'triss',
  'triss-40-kr': 'triss',
  'triss-90': 'triss',
  'triss-150': 'triss',
  'triss-premium': 'triss',
  'triss-adventskalender': 'triss',
  'vinnare-new': 'vinnare'
};
const getGuard = (brand) => {
  let match = brand
    .trim()
    .replace(/[ÅÄåä]/g, 'a')
    .replace(/[Öö]/g, 'o')
    .replace(/Éé/g, 'e')
    .replace(/ /g, '-')
    .replace(/[^A-Za-z0-9_-]/g, '')
    .toLowerCase();

  if (guards[match]) {
    match = guards[match];
  }

  return match;
};

const getBrand = (brand, size) => {
  let hashedStr = getGuard(brand);
  const guardedBrand = hashedStr;

  if (hashedBrands[hashedStr] && size > 0) {
    hashedStr = hashedBrands[`${hashedStr}-${size}`] ? `${hashedStr}-${size}` : hashedStr;
  }

  return {
    brand: guardedBrand,
    svg: hashedBrands[hashedStr] && hashedBrands[hashedStr].svg ? hashedBrands[hashedStr].svg : '',
    alt: hashedBrands[hashedStr] && hashedBrands[hashedStr].alt ? hashedBrands[hashedStr].alt : ''
  };
};

const getEmblem = () => hashedBrands[definedEmblem].svg;

const getAssetUrl = (brand) => cdn + path + brand;

export default {
  getAssetUrl,
  getBrand,
  getEmblem
};
